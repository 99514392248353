import request from "@/utils/request";

export function getConselingList(data) {
  return request({
    method: "post",
    url: "conseling/list",
    data: data
  });
}

export function getConselingById(data) {
  return request({
    method: "post",
    url: "conseling/detail",
    data: data
  });
}

export function deleteConselingStudent(data) {
  return request({
    method: "post",
    url: "conseling/delete",
    data: data
  });
}

export function getMasterConseling(data) {
  return request({
    method: "post",
    url: "conseling/list-master",
    data: data
  });
}

export function addConseling(data) {
  return request({
    method: "post",
    url: "conseling/add",
    data: data
  });
}

export function maxPoint(data) {
  return request({
    method: "get",
    url: "conseling/max-point",
    data: data
  });
}

export function addMaxPoint(data) {
  return request({
    method: "post",
    url: "conseling/add-max-point",
    data: data
  });
}

export function addMasterPoint(data) {
  return request({
    method: "post",
    url: "conseling/add-master",
    data: data
  });
}

export function deleteMasterPoint(data) {
  return request({
    method: "post",
    url: "conseling/delete-master",
    data: data
  });
}

export function updatePublish(data) {
  return request({
    method: "post",
    url: "conseling/update",
    data: data
  });
}

export function updateMaster(data) {
  return request({
    method: "post",
    url: "conseling/update-master",
    data: data
  });
}
