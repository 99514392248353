<template>
  <v-card class="pa-4" flat>
    <div class="d-flex align-center flex-wrap ">
      <div class="font-weight-bold" style="cursor: pointer;">
        <v-btn depressed @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <v-btn
        to="bk-points"
        class="gradient-primary ma-2"
        dark
        depressed
        @click="modalmaxpoin = true"
      >
        {{ $t("app.edit") }}
        <v-icon class="ml-2">mdi-pencil-box-outline</v-icon>
      </v-btn>
      <div class="ml-1">
        {{ $t("bk.violation_max_point") }} =
        <span class="font-weight-bold">{{ labelMaxpoint }}</span>
      </div>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-btn
        class="gradient-primary ma-2"
        dark
        depressed
        @click="toggleDialogAddPoint"
      >
        {{ $t("bk.add_point") }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>

    <v-divider class="mb-4 mt-3" />

    <div v-if="selected.length > 0" class="d-flex justify-end">
      <v-btn
        depressed
        color="error"
        class="mb-3"
        small
        @click="deleteConfirm = true"
        >{{ $t("bk.delete_points") }}</v-btn
      >
    </div>

    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      disable-pagination
      fixed-header
      height="500"
      show-select
      hide-default-footer
      class="elevation-0"
    >
      <template #item.type="{ item }">
        <v-btn
          v-if="item.type === 'foul'"
          depressed
          small
          color="error"
          class="disable-events"
        >
          <v-icon small class="mr-2">mdi-block-helper</v-icon>
          {{ $t("bk.violation") }}
        </v-btn>
        <v-btn v-else depressed small color="primary" class="disable-events">
          <v-icon small class="mr-2">mdi-trophy</v-icon>
          {{ $t("bk.award") }}
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-btn
            :disabled="selected.length > 0"
            icon
            small
            dark
            class="primary mr-2"
            @click="editPoint(item)"
          >
            <v-icon small>mdi-pencil-box-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="selected.length > 0"
            icon
            small
            dark
            class="error"
            @click="confirmDialog(item.id)"
          >
            <v-icon small>mdi-delete-forever</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="modalmaxpoin" width="500" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t("bk.add_violation_max_point") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="form_maxpoint"
            v-model="form_maxPoint"
            lazy-validation
            class="mt-5"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="maxpoint"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('bk.max_point')"
                  outlined
                  dense
                  type="number"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingMaxPoint"
            color="primary"
            outlined
            @click="modalmaxpoin = false"
          >
            {{ $t("app.back") }}
          </v-btn>
          <v-btn
            :loading="loadingMaxPoint"
            color="primary"
            depressed
            @click="saveMaxPoint"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modaladdpoin" width="500" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ isEditPoint ? $t("bk.update_point") : $t("bk.add_point") }}
          <v-spacer />
          <v-btn
            :disabled="loadingAddPoint"
            small
            text
            @click="toggleDialogAddPoint"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form_point" v-model="bkPoints" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="form_poin.type"
                  :disabled="loadingAddPoint"
                  mandatory
                  row
                >
                  <v-radio :label="$t('bk.award')" value="award" />
                  <v-radio :label="$t('bk.violation')" value="foul" />
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form_poin.point"
                  :rules="[v => !!v || $t('app.required')]"
                  :disabled="loadingAddPoint"
                  label="Point"
                  outlined
                  dense
                  type="number"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <!-- hidden date picker -->
                <DatePicker
                  v-if="false"
                  :label="$t('bk.date')"
                  :disabled="loadingAddPoint"
                  notitle
                  outlined
                  dense
                  @getValue="v => (form_poin.date = v)"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form_poin.description"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('bk.category')"
                  :disabled="loadingAddPoint"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingAddPoint"
            color="primary"
            outlined
            @click="toggleDialogAddPoint"
          >
            {{ $t("app.back") }}
          </v-btn>
          <v-btn
            :loading="loadingAddPoint"
            class="gradient-primary"
            dark
            depressed
            @click="savePoint"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirm
      :close="() => (this.deleteConfirm = false)"
      :loading="loadingDelete"
      :isOpen="deleteConfirm"
      :save="deletePoint"
    />
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import {
  getMasterConseling,
  maxPoint,
  addMaxPoint,
  addMasterPoint,
  deleteMasterPoint,
  updateMaster
} from "@/api/admin/academic/conseling";

export default {
  components: {
    DatePicker: () => import("@/components/DatePicker"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      deleteConfirm: false,
      loadingDelete: false,
      selected: [],
      currentDate: "",
      loading: false,
      modalmaxpoin: false,
      modaladdpoin: false,
      bkPoints: false,
      form_maxPoint: false,
      maxpoint: 0,
      idMaxpoint: 0,
      labelMaxpoint: 0,
      tableHeaders: [
        {
          text: i18n.t("bk.table.point"),
          value: "point"
        },
        {
          text: i18n.t("bk.table.desc"),
          value: "description"
        },
        {
          text: i18n.t("bk.table.type"),
          value: "type"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 150
        }
      ],
      dataTable: [],
      form_poin: {
        type: "award",
        date: "",
        description: ""
      },
      loadingMaxPoint: false,
      loadingAddPoint: false,
      isEditPoint: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    goBack() {
      const query = this.$route.query;
      this.$router.push({
        name: "BK",
        query: { year: query.year, grade: query.grade, class: query.class }
      });
    },
    toggleDialogAddPoint() {
      if (this.modaladdpoin) this.resetFormAddPoint();
      this.modaladdpoin = !this.modaladdpoin;
      this.isEditPoint = false;
    },
    resetFormAddPoint() {
      this.$refs.form_point.reset();
      this.$refs.form_point.resetValidation();
    },
    async getMaxPoint() {
      const response = await maxPoint();
      if (response.data.code) {
        const maxPoint = response.data.data;
        this.maxpoint = maxPoint.point;
        this.idMaxpoint = maxPoint.id;
        this.labelMaxpoint = maxPoint.point;
      } else {
        this.snackBar(true, response.data.message);
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async initData() {
      this.loading = true;
      this.getMaxPoint();
      const response = await getMasterConseling({ type: "" });
      if (response.data.code) {
        this.dataTable = response.data.data.data;
      } else {
        this.snackBar(true, response.data.message);
      }
      this.loading = false;
    },
    editPoint(param) {
      const getPoint = this.dataTable.find(d => d.id == param.id);
      this.form_poin = JSON.parse(JSON.stringify(getPoint));
      this.modaladdpoin = true;
      this.isEditPoint = true;
    },
    confirmDialog(id) {
      this.deleteConfirm = true;
      this.idDelete = id;
    },
    async deletePoint() {
      this.loadingDelete = true;
      const idPoint =
        this.selected.length > 0
          ? this.selected.map(item => item.id)
          : [this.idDelete];
      const response = await deleteMasterPoint({ id: idPoint });
      if (response.data.code) {
        this.snackBar(false, this.$i18n.t("bk.msg.delete_point"));
      } else {
        this.snackBar(true, response.data.message);
      }
      this.deleteConfirm = false;
      this.initData();
      this.selected = [];
      this.loadingDelete = false;
    },
    async savePoint() {
      if (this.$refs.form_point.validate()) {
        this.loadingAddPoint = true;
        const response = this.isEditPoint
          ? await updateMaster(this.form_poin)
          : await addMasterPoint(this.form_poin);
        if (response.data.code) {
          this.snackBar(false, this.$i18n.t("bk.msg.added_point"));
        } else {
          this.snackBar(true, response.data.message);
        }
        this.toggleDialogAddPoint();
        this.initData();
        this.loadingAddPoint = false;
      }
    },
    async saveMaxPoint() {
      if (this.$refs.form_maxpoint.validate()) {
        this.loadingMaxPoint = true;
        const response = await addMaxPoint({
          point: this.maxpoint,
          id: this.idMaxpoint
        });
        if (response.data.code) {
          this.snackBar(false, this.$i18n.t("bk.msg.max_point"));
        } else {
          this.snackBar(true, response.data.message);
        }
        this.loadingMaxPoint = false;
        this.modalmaxpoin = false;
        this.initData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.disable-events {
  pointer-events: none;
}
</style>
